import { defineComponent } from 'vue';
import AnnualEnvironments from '@/views/AnnualEnvironment/AnnualEnvironments.vue';
export default defineComponent({
    name: 'AnnualEnvironmentLayout',
    components: { AnnualEnvironments },
    data() {
        return {
            loading: false,
            tab: 'Annual Environments'
        };
    }
});
